@font-face {
  font-family: "DancingScript";
  src: url("./DancingScript.ttf");
}

@font-face {
  font-family: "Dosis";
  src: url("./Dosis.ttf");
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 130px;
}

a {
  text-decoration: none;
}

.googleLoginButton {
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
}
